.ReactModal__Body--open {
  overflow: hidden;
}

button {
  border: 0;
  outline: 0;
  padding: 0;
  line-height: 1.45;
  cursor: pointer;
  font: inherit;
  background: none;
}
